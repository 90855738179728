import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import ContactForm from 'components/contact/contactForm'
import { media } from 'utils/Media'

const ContactBodyStyles = styled.section`
  width: 100%;
  padding-bottom: 2rem;
  min-height: calc(90vh - 210px);

  .c-b {
    width: 85%;
    margin: var(--auto);
    max-width: var(--maxWidth);
    &--main {
      padding-top: 2rem;
      @media ${media.md} {
        padding-top: 8rem;
      }

      h1 {
        font-size: 2.3rem;
        font-weight: 800;
        letter-spacing: 2px;
        margin: 0;
        color: var(--cyan);
        text-transform: uppercase;
        animation: fade-in-bottom 0.6s 0.15s cubic-bezier(0.39, 0.575, 0.565, 1)
          both;
      }
      h2 {
        margin-top: 0.5rem;
        font-size: 2rem;
        font-weight: 900;
        line-height: 1.1;
      }
    }
    &--map {
      margin-top: 1rem;

      &--wrapper {
        margin-top: 2rem;
        .gatsby-image-wrapper {
          height: 25rem;
        }
        @media ${media.md} {
          margin-top: 0;
        }
      }
      section {
        margin: 1.5rem 0 0 0;
        p {
          font-weight: 800;
          font-size: 1.2rem;
          text-transform: uppercase;
          color: var(--cyan);
          margin-top: 0.5rem;
        }
        a {
          font-size: 1.5rem;
          margin-top: 0.25rem;
          display: block;
        }
      }
    }
    @media ${media.sm} {
      &--main {
        padding-top: 2rem;
      }
    }
    @media ${media.md} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: 'a b';
      grid-gap: 1rem;
      padding-top: 1rem;
      &--main {
        padding-top: 1rem;
        grid-area: b;
      }
    }
    @media ${media.slg} {
      grid-gap: 4rem;
      padding-top: 2rem;
    }
    @media ${media.xl} {
      grid-gap: 6rem;
      grid-template-columns: 52% 1fr;
      &--main {
        width: 90%;
        h1 {
          margin: 0;
        }
        h2 {
          font-size: 2.65rem;
          margin-bottom: 5rem;
        }
      }
    }
  }
  @media ${media.slg} {
    min-height: calc(90vh - 210px);
  }
`
const ContactBody = () => (
  <ContactBodyStyles>
    <div className="c-b">
      <div className="c-b--main">
        <h1 className="fade-1">Contact us</h1>
        <h2 className="fade-1">
          If you want all eyes on you, come and <br /> say hello
        </h2>
        <ContactForm />
      </div>
      <div className="c-b--map">
        <div className="c-b--map--wrapper">
          <StaticImage
            src="../../images/map.jpg"
            alt="Dewynters | Wellington House | 125 Strand | London | WC2R 0AP"
            className="fade-2"
          />
        </div>
        <section className="fade-4">
          <p>General enquiries</p>
          <a href="mailto:info@dewynters.com">info@dewynters.com</a>
        </section>
        <section className="fade-4">
          <p>Call us</p>
          <a href="tel:+442073210488">+44 207 321 0488</a>
        </section>
      </div>
    </div>
  </ContactBodyStyles>
)

export default ContactBody

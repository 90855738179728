import React, { useState,  useRef } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'

import AlertIcon from 'svgs/alert.svg'
import SuccessMessage from 'components/functional/successMessage'


const ContactFormStyles = styled.section`
  width: 100%;
  margin-top: 2rem;
  form {
    label {
      font-size: 1.1rem;
    }

    input,
    textarea {
      display: block;
      border: none;
      border-bottom: solid 2px #b8b8b8;
      border-radius: 0;
      width: 100%;
      transition: all 0.35s ease;
      font-size: 1.2rem;
      &:focus,
      &:hover {
        outline: none;
        &::placeholder {
          color: var(--cyan);
          /* font-size: 0.95rem; */
          /* transform: translateY(-25px); */
        }
      }
      &::placeholder {
        color: var(--black);
        font-size: 1.2rem;
        transition: all 0.35s ease;
      }
    }

    input {
      padding: 0.45rem 0.05rem 0.65rem 0.05rem;
      margin-bottom: 1.25rem;
    }
    textarea {
      resize: vertical;
      height: 10rem;
    }
  }
  .errors {
    margin-top: 1.5rem;
  }
  button {
    background-color: var(--black);
    border: none;
    padding: 1.35rem 0;
    width: 145px;
    margin-top: 1.5rem;
    transition: all 0.25s ease-in-out;
    &:hover,
    &:active {
      background-color: var(--cyan);
      span {
        color: var(--black);
      }
    }
    span {
      color: var(--white);
      text-transform: uppercase;
      letter-spacing: 2px;
      transition: all 0.25s ease-in-out;
    }
  }
`
const ContactForm = () => {
  const formRef = useRef(null);
  const {
    control,
    register,
    setError,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const [sendingFormRequest, setSendingFormRequest] = useState(false);
  const [success, setSuccess] = useState(false);
  const [sending, setSending] = useState(false);
  const [hasError, setHadError]  = useState(null);
  const handleFormSubmit = async (formFieldData) => {
    if (sendingFormRequest) return;
    if (formFieldData.honey) return; // Honey pot field
    setSendingFormRequest(true);

    console.log(formFieldData);

    try {
      const response = await fetch('/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formFieldData),
      });

      if (!response.ok) {
        throw new Error('Error sending email');
      }

      if (response.status === 200) {
        setSending(false);
        setSuccess(true);
        reset(); // Reset the form
        // router.push('/thank-you/');

        //NEED TO SEND TO THANK YOU PAGE
      }
    } catch (error) {
      setSending(false);
      console.error('Error sending email:', error);
      setError('Message', { message: 'Message failed to send' });
    } finally {
      setSendingFormRequest(false);
    }
  };
  return (
    <ContactFormStyles>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        noValidate
        className="fade-4"
        id="form"
      >
        <input
          {...register('honey')}
          type="text"
          id="yourName"
          name="Name"
          placeholder="Your name here"
          className="sr-only"
          autoComplete="none"
          tabIndex="-1"
          style={{ display: 'none'}}
        />
        <input
          {...register('name', {
            required: 'A name is required',
          })}
          placeholder="Name"
          type='text'
          name="name"
        />
        <input
          {...register('email', {
            required: 'An email is required',
            pattern: {
              value:
                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              message: 'Invalid email address',
            },
          })}
          type='email'
          placeholder="Email"
          name="email"
        />
        <input
          {...register('subject', {
            required: 'A subject is required',
          })}
          type='text'
          placeholder="Subject"
          name="subject"
        />
        <textarea
          {...register('message', {
            required: 'A message is required',
          })}
          placeholder="Message"
          name="message"
        />
        <section className="errors">
          {errors.name && <ErrorMessage message={errors.name.message} />}
          {errors.email && <ErrorMessage message={errors.email.message} />}
          {errors.subject && <ErrorMessage message={errors.subject.message} />}
          {errors.message && <ErrorMessage message={errors.message.message} />}
        </section>
        <button type="submit">
          <span>submit</span>
        </button>
      </form>
      <SuccessMessage success={success} setSuccess={setSuccess} />
    </ContactFormStyles>
  )
}

export default ContactForm

const ErrorMessageStyles = styled.aside`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem 0;
  align-items: center;
  svg {
    fill: red;
    width: 22px;
    height: 22px;
  }
  p {
    margin-left: 10px;
    color: red;
  }
`
const ErrorMessage = ({ message }) => (
  <ErrorMessageStyles>
    <AlertIcon />
    <p>{message}</p>
  </ErrorMessageStyles>
)

import React from 'react'
import MainNav from '../components/functional/mainNav'
import Layout from '../components/layout'
import ContactBody from '../components/contact/contactBody'

const metaData = {
  title: 'Contact Us | D E W Y N T E R S',
  description: `We’re Dewynters, the leading integrated live entertainment advertising agency. Based in London, we create best in class campaigns for theatre, immersive, festivals and exhibitions. | Contact us`
}

const ContactUs = () => (
  <Layout title={metaData.title} description={metaData.description}>
    <MainNav alt />
    <ContactBody />
  </Layout>
)

export default ContactUs
